import React from "react";
import ns from "../../../globals/js/utils/namespace";

// TODO: remove any
interface Props {
  item: any;
  onMouseEnter: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onMouseLeave: () => void;
}

export default function TimelineCard({
  item,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  return (
    <a
      className={ns("timeline-card")}
      href={item.url}
      onMouseEnter={(e) => onMouseEnter(e)}
      onMouseLeave={onMouseLeave}
    >
      <div className={ns("timeline-card-content")}>
        <h3 className={ns("timeline-card-content-date")}>
          <span className={ns("timeline-card-content-date__day")}>
            {item.title.slice(0, 2)}
          </span>
          <span className={ns("timeline-card-content-date__month-year")}>
            {item.cardSubtitle}
          </span>
        </h3>
        <p className={ns("timeline-card-content__title")}>{item.cardTitle}</p>
      </div>

      <figure className={ns("timeline-card-image")}>
        <div className={ns("timeline-card-image-container")}>
          <picture className={ns("timeline-card-image-container__picture")}>
            {/* XL: For extra large screens */}
            <source
              media="(min-width: 80em)"
              srcSet={item.media.source.srcSetXL}
            />
            {/* LG: For large screens */}
            <source
              media="(min-width: 60em)"
              srcSet={item.media.source.srcSetLG}
            />
            {/* MD: For medium screens */}
            <source
              media="(min-width: 40em)"
              srcSet={item.media.source.srcSetMD}
            />
            {/* SM: For small screens */}
            <source
              media="(min-width: 30em)"
              srcSet={item.media.source.srcSetSM}
            />
            {/* XS: For small screens */}
            <source srcSet={item.media.source.srcSetXS} />
            {/* Fallback */}
            <img
              className={ns("timeline-card-image-container__picture-img")}
              src={item.media.source.srcSetFallback}
              // TODO: alt from backend
              alt="Verordnung des thüringischen Ministers des Innern zur Ausführung der Verordnung des Reichspräsidenten zum Schutz der Republik vom 24. Juni 1922"
            />
          </picture>
        </div>
        <figcaption className={ns("timeline-card-image-caption")}>
          <p className={ns("timeline-card-image-caption__copyright")}>
            {item.cardDetailedText}
          </p>
        </figcaption>
      </figure>
    </a>
  );
}
