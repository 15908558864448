import React from "react";
import ns from "./globals/js/utils/namespace";
import "./App.scss";
import Timeline from "./components/organisms/timeline/Timeline";
import { DataArray } from "./weimarEvents.types";

interface Props {
  weimarEvents: DataArray;
}

function App({ weimarEvents }: Props) {
  return (
    <div className={ns("app")} data-testid={ns("app")}>
      <Timeline weimarEvents={weimarEvents} />
    </div>
  );
}

export default App;
