import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import ns from "./globals/js/utils/namespace";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const domReady = (callBack: () => void) => {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", callBack);
  } else {
    callBack();
  }
};

domReady(() => {
  const container = document.getElementById(ns("root")) as HTMLElement;

  // TODO: Remove all the below console.log's

  console.log("TEST🚀 ~ file: index.tsx:10 ~ container", container);
  const root = createRoot(container!);

  // Using local items.json file for testing
  // const weimarEvents = require("./globals/weimarEvents.json");

  const weimarEventsAttr = container.getAttribute(
    "data-js-wmrz-events",
  ) as string;
  console.log("🚀 ~ file: index.tsx:19 ~ weimarEventsAttr", weimarEventsAttr);
  const weimarEvents = JSON.parse(decodeURIComponent(weimarEventsAttr));
  console.log("🚀 ~ file: index.tsx:21 ~ weimarEvents", weimarEvents);

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Routes>
            <Route path="/*" element={<App weimarEvents={weimarEvents} />} />
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
    </React.StrictMode>,
  );
});
