import React, { useRef } from "react";
import { Chrono } from "react-chrono";
import { TimelineItem } from "react-chrono";
import ns from "../../../globals/js/utils/namespace";
import { useQueryParam, NumberParam } from "use-query-params";
import TimelineCard from "../../molecules/timeline-card/TimelineCard";
import { useMediaQuery } from "usehooks-ts";
import { DataArray, WeimarEvent } from "../../../weimarEvents.types";

interface Props {
  weimarEvents: DataArray;
}

export default function Timeline({ weimarEvents }: Props) {
  const [activeItemIndex, setActiveItemIndex] = useQueryParam(
    "active",
    NumberParam,
  );

  const chronoDivWrapperRef = useRef<HTMLDivElement | null>(null);

  const screenXl = useMediaQuery("(min-width: 1280px)");
  const screenLg = useMediaQuery("(min-width: 960px)");
  const screenMd = useMediaQuery("(min-width: 640px)");

  const titles = weimarEvents.dataArray.map((item: WeimarEvent) => {
    return {
      title: item.title,
    };
  });

  // set active item in param for browser's back button
  const handleItemSelected = (item: TimelineItem) => {
    const index = weimarEvents.dataArray.findIndex((object: WeimarEvent) => {
      return object.title === item.title;
    });

    setActiveItemIndex(index);
  };

  // find closest day to today 100 years ago
  const getIndexOfClosestDate = () => {
    const today = new Date();
    const hundredYearsAgo = today.setFullYear(today.getFullYear() - 100);

    const datesArr = weimarEvents.dataArray.map(
      (item: WeimarEvent) => item.dateValue,
    );

    const distanceFromNowArr = datesArr.map((d) =>
      Math.abs(hundredYearsAgo - new Date(d).getTime()),
    );
    const idxOfClosestDate = distanceFromNowArr.indexOf(
      Math.min(...distanceFromNowArr),
    );

    return idxOfClosestDate;
  };

  // highlight timeline point when hovering over card
  const handleMouseEnter = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (chronoDivWrapperRef.current) {
      const target = e.target as HTMLAnchorElement;
      const card = target.getElementsByClassName(
        "wmrz-timeline-card-content__title",
      )[0];
      const cardTitleText = card.innerHTML;
      const cardHoveredIdx = weimarEvents.dataArray.findIndex(
        (el: WeimarEvent) => el.cardTitle === cardTitleText,
      );
      const hoveredItemPoint =
        chronoDivWrapperRef.current.getElementsByClassName(
          "timeline-horz-item-container",
        )[cardHoveredIdx];

      hoveredItemPoint.classList.add("hovered");
    }
  };

  // remove highlight point on mouse leave
  const handleMouseLeave = () => {
    if (chronoDivWrapperRef.current) {
      Array.from(
        chronoDivWrapperRef.current.querySelectorAll(
          ".timeline-horizontal-container li",
        ),
      ).forEach((element) => element.classList.remove("hovered"));
    }
  };

  return (
    <>
      {titles && (
        <div
          ref={chronoDivWrapperRef}
          style={{ width: "100%", height: "auto" }}
        >
          <Chrono
            items={titles}
            mode="HORIZONTAL"
            showAllCardsHorizontal
            focusActiveItemOnLoad={true}
            activeItemIndex={
              typeof activeItemIndex === "number"
                ? activeItemIndex
                : getIndexOfClosestDate()
            }
            onItemSelected={(item) => handleItemSelected(item)}
            cardHeight={screenXl ? 305 : screenLg ? 295 : screenMd ? 240 : 270}
            cardWidth={screenXl ? 545 : screenLg ? 525 : screenMd ? 400 : 270}
            itemWidth={screenXl ? 300 : screenLg ? 250 : screenMd ? 220 : 180}
            lineWidth={4}
            classNames={{
              card: ns("timeline-section"),
              title: ns("timeline__title"),
              controls: ns("timeline-controls"),
            }}
            theme={{
              primary: "#727272",
              secondary: "#cc4756",
              titleColor: "black",
              titleColorActive: "black",
            }}
            buttonTexts={{
              first: "Zum Ersten springen",
              last: "Zum Letzten springen",
              next: "Weiter",
              previous: "Zurück",
            }}
            fontSizes={{
              title: "1.2rem",
            }}
          >
            {weimarEvents.dataArray.map((item: WeimarEvent, i: React.Key) => (
              <TimelineCard
                key={i}
                item={item}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            ))}
          </Chrono>
        </div>
      )}
    </>
  );
}
